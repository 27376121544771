.location {
  align-self: flex-start;
  margin: 0.8rem 0 0 0.8rem;
}

.location-content {
  color: #4569a3;
}

.link {
  color: #3782c4;
  cursor: pointer;
}

.location-link {
  margin-left: 2rem;
}

.location-link:hover {
  color: #76a0c4;
}
