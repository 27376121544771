.icon {
  height: 2rem;
}

.logo {
  color: #61dafb;
  text-decoration: none;
  padding: 0.15rem;
}

.profile {
  align-self: flex-end;
  padding: 0 0.5rem 0.5rem 0;
}
