.app {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (prefers-reduced-motion: no-preference) {
}

.name {
  /* margin-left: auto;
  margin-right: auto; */
  /* position: relative;
  top: 40%;
  left: 40%; */
  font-size: calc(10px + 2vmin);
}

.name-text {
  padding: 2rem 2rem 2rem 0.2rem;
  margin-right: 10rem;
}

.app-link {
  color: #61dafb;
  text-decoration: none;
}
